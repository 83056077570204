import React, { useState } from 'react';
import { Search, Bot, Loader2 } from 'lucide-react';
import { useCelebritySearch } from '../../hooks/useCelebritySearch';
import { CelebritySearchResult } from './CelebritySearchResult';
import { StardomAIAssistant } from '../ai/StardomAIAssistant';
import { StoriesSection } from '../community/StoriesSection';

export function SearchHero() {
  const [query, setQuery] = useState('');
  const [showAI, setShowAI] = useState(false);
  const { isLoading, error, data, searchCelebrity } = useCelebritySearch();

  const handleSearch = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (!query.trim() || isLoading) return;
    await searchCelebrity(query);
  };

  return (
    <div className="flex flex-col px-4 bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
      <div className="w-full max-w-3xl pt-8 pb-4">
        <form onSubmit={handleSearch} className="relative mb-8">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for any public figure"
              className="w-4/5 pl-12 pr-20 py-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg focus:outline-none focus:ring-2 focus:ring-[#F8CA7E] text-lg dark:text-white"
            />
            <div className="absolute right-[20%] top-1/2 -translate-y-1/2 flex gap-2">
              <button 
                type="button"
                onClick={() => setShowAI(true)}
                className={`p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors ${
                  isLoading ? 'bg-[#F8CA7E] text-white' : 'text-gray-600 dark:text-gray-400'
                }`}
                title="Ask StardomAI about this celebrity"
              >
                <Bot className="h-5 w-5" />
              </button>
              <button 
                type="submit"
                disabled={isLoading}
                className="bg-[#F8CA7E] text-white p-2 rounded-lg hover:bg-[#e5b86b] transition-colors disabled:opacity-50"
              >
                {isLoading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Search className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="absolute top-full left-0 right-0 mt-2 p-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}
        </form>

        <div className="mt-4">
          <StoriesSection />
        </div>

        {data && (
          <div className="mt-8">
            <CelebritySearchResult data={data} />
          </div>
        )}
      </div>

      {showAI && <StardomAIAssistant onClose={() => setShowAI(false)} />}
    </div>
  );
}